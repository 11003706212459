.svgimgBoxDark
{
    background-color: #404e9d;
    opacity: 1.5;
    border-radius: 50%;
    /*-webkit-transform: translate(-15px, -30px);
            transform: translate(-15px, -30px);*/
}

.svgimgDark{
    height: 30px;
    opacity: 0.3;
    overflow: overlay;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 55 55' style='enable-background:new 0 0 55 55;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23173362;enable-background:new ;%7D .st1%7Bfill:%232D4362;enable-background:new ;%7D .st2%7Bfill:%237387A1;enable-background:new ;%7D .st3%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Ccircle id='Ellipse_2_2_' class='st0' cx='27.5' cy='27.5' r='23.5'/%3E%3Ccircle id='Ellipse_2_1_' class='st1' cx='27.5' cy='27.5' r='18.4'/%3E%3Ccircle id='Ellipse_2' class='st2' cx='27.5' cy='27.5' r='13.7'/%3E%3Ccircle id='Ellipse_1' class='st3' cx='27.3' cy='27.7' r='8.5'/%3E%3C/svg%3E%0A");
}


.svgimgBoxRegular
{
    background-color: #44629870;
    opacity: 1.5;
    border-radius: 50%;
    -webkit-transform: translate(-15px, -30px);
            transform: translate(-15px, -30px);
}

.svgimgRegular{
    height: 30px;
    opacity: 0.6;
    overflow: overlay;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 55 55' style='enable-background:new 0 0 55 55;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%2397B5E8;enable-background:new ;%7D .st1%7Bfill:%23B5C6DE;enable-background:new ;%7D .st2%7Bfill:%23B9CEEB;enable-background:new ;%7D .st3%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Ccircle id='Ellipse_2_2_' class='st0' cx='27.5' cy='27.5' r='23.5'/%3E%3Ccircle id='Ellipse_2_1_' class='st1' cx='27.5' cy='27.5' r='18.4'/%3E%3Ccircle id='Ellipse_2' class='st2' cx='27.5' cy='27.5' r='13.7'/%3E%3Ccircle id='Ellipse_1' class='st3' cx='27.3' cy='27.7' r='8.5'/%3E%3C/svg%3E%0A");
}

.svgimgBoxlight
{
    background-color: #076b7270;
    opacity: 1.5;
    border-radius: 50%;
    -webkit-transform: translate(-15px, -30px);
            transform: translate(-15px, -30px);
}

.svgimglight{
    height: 30px;
    opacity: 0.6;
    overflow: overlay;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 55 55' style='enable-background:new 0 0 55 55;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%233B86FF;enable-background:new ;%7D .st1%7Bfill:%2376AEFF;enable-background:new ;%7D .st2%7Bfill:%23B6D7FF;enable-background:new ;%7D .st3%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Ccircle id='Ellipse_2_2_' class='st0' cx='27.5' cy='27.5' r='23.5'/%3E%3Ccircle id='Ellipse_2_1_' class='st1' cx='27.5' cy='27.5' r='18.4'/%3E%3Ccircle id='Ellipse_2' class='st2' cx='27.5' cy='27.5' r='13.7'/%3E%3Ccircle id='Ellipse_1' class='st3' cx='27.3' cy='27.7' r='8.5'/%3E%3C/svg%3E%0A");
}